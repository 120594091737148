@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  max-width: 16rem;
}

.titleContainer {
  display: flex;
  flex: 0;
  flex-direction: column;
  padding: 1.5rem 0 1rem 0;
}

.linkContainer {
  display: flex;
  flex: 0;
  flex-direction: column;
}

.name {
  @include Snavyleft;
}

.title {
  @include XSgrey_mediumleft;
  margin-bottom: 0.5rem;
}

.link {
  display: flex;
  align-items: center;
  text-decoration: none;
  @include Sblueleft;
  height: 3rem;
  width: 16rem;
  border-bottom: 1px solid $contrast-grey-medium;
  cursor: pointer;

  &.active {
    @include Snavyleft;
  }
}

.navNumber {
  width: 1.5rem;
}
