@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.activeBadge {
  background-color: $main-navy-full;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  max-width: 100%;

  &:last-child {
    margin-right: 0;
  }
}

.input {
  border-width: 0;
}

.label {
  flex: 1;
  justify-content: center;
}

.subsection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  margin-right: 1rem;
}

.title {
  @include Lnavyleft;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
}

.container {
  padding-bottom: 2rem;
}

.dropDownContainer {
  margin-top: 2rem;
}

.motiveDropDown {
  width: 40rem;
  background-color: $main-white-full;
}

.unitDropDown {
  width: 10rem;
  background-color: $main-white-full;
  margin-left: 1rem;
}

.openDurationContainer {
  display: flex;
}

.charCountLabel {
  @include XSnavyleft;
}

.textArea {
  height: 10rem;
  margin-bottom: 1rem;
}

.textInput {
  width: 10rem;
}

.separator {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.fillContractTypeMessage {
  margin-bottom: 0.5rem;
}

.generalInformationLinkContainer {
  margin-bottom: 1.5rem;
  text-decoration: none;
}

.generalInformationLink {
  text-decoration: none;
  color: $main-blue-full;
  &:visited {
    color: $main-blue-full;
  }
}

.contractContainer {
  display: flex;
}

.trialPeriodContainer {
  margin-left: 1.5rem;
}
.inputNumberContainer {
  width: 9rem;
  input {
    width: 7rem;
  }
}

.cddDurationContainer {
  margin-left: 1.5rem;
}

.statusContainer {
  margin-top: 2rem;
}
