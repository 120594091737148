@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.strategySubtitle {
  @include Lnavyleft;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
}

.textInput {
  width: 100%;
}

.textArea {
  height: 10rem;
  margin-bottom: 2.5rem;
}

.charCountLabel {
  @include XSnavyleft;
}

.errorMessage {
  @include XSerrorleft;
  margin-top: 0.25rem;
}

.separator {
  height: 1px;
  background-color: $contrast-grey-medium;
}
