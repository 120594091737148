@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  margin-top: 1rem;
}

.separator {
  height: 1px;
  background-color: $contrast-grey-light;
}

.inputError {
  border-color: $system-danger !important;
}

.errorMessage {
  color: $system-danger;
  margin-top: 0.5rem;
  font-size: 0.75rem;
}

.competitorCard {
  border: solid 1px var(--main-white-none);
}

.icon {
  margin-top: -1.5rem;
}

.buttonIcon {
  width: 1rem;
  height: 1rem;
}

.addButton {
  margin-bottom: 1rem;
}
