@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.activeBadge {
  background-color: $main-navy-full;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  max-width: 100%;

  &:last-child {
    margin-right: 0;
  }
}

.separator {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.counter {
  @include XSnavyleft;
}

.textArea {
  height: 10rem;
}

.emailCommentContainer {
  margin-top: 1rem;
}
