@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.currentRecruitmentSteps {
  margin-top: 0.75rem;
  margin-bottom: 0.5rem;
  @include XSgrey_mediumleft;
}

.addHierarchy {
  color: $main-blue-full;
  margin-left: 0.25rem;
}

.addHierarchyIcon {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}

.modalTrigger {
  display: flex;
  flex-direction: row;
  width: 15rem;
  cursor: pointer;
  margin-top: 1.5rem;
}

.modifyTrigger {
  display: flex;
  flex-direction: row;
  width: 5rem;
  cursor: pointer;
  color: $main-blue-full;
  position: relative;
  bottom: 1.125rem;
  left: calc(100% - 5rem);
}

.chartContainer {
  background-color: white;
  margin-top: 2rem;
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
  max-width: 60rem;
  overflow-x: auto;
}

.keyPersonsTitle {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.separator {
  height: 1px;
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
  background-color: $contrast-grey-medium;
}

.textArea {
  height: 10rem;
}

.counter {
  @include XSnavyleft;
}

.containerDrag {
  :hover {
    background-color: $contrast-grey-lighter;
  }

  :active {
    background-color: $contrast-grey-light;
  }
}

.recruitmentStepsStyle {
  margin-top: 0.75rem;
  width: 99.5%;
}
