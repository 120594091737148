@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  margin-top: 1rem;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.startTimePicker {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.endTimePicker {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  margin-left: -1px;
}

.daysContainer {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
}

.removeButton {
  margin-top: 1.5rem;
  padding-left: 0.5rem;
  padding-right: 1rem;

  height: 3.5rem;

  &:hover {
    cursor: pointer;
  }
}

.removeButtonIcon {
  width: 1rem;
  height: 1rem;
}
