@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  padding: 1rem 1rem 1rem 2.25rem;
  padding-bottom: 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.container::-webkit-scrollbar {
  display: none;
}

.topBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 0;
  margin-bottom: 1.5rem;
}

.tabsContainer {
  flex: 0;
}

.tabContainer {
  flex: 1;
  flex-direction: row;
  display: flex;
  overflow-y: scroll;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.tabContainer::-webkit-scrollbar {
  display: none;
}

.loader {
  margin-top: 10rem;
  margin-left: 10rem;
}

.errorContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-top: 1rem;
  margin-left: 2rem;
}

.errorYellowLadder {
  margin-bottom: 1rem;

  svg {
    width: 13.188rem;
    height: 16rem;
    object-fit: contain;
  }
}

.errorTitle {
  @include Lnavyleft;
  margin-bottom: 0.25rem;
}

.errorDescription {
  @include Mgreenishleft;
}

.bigIcon {
  min-width: 3rem;
  min-height: 3rem;
  color: $main-white-full;
}

.trigger {
  width: 2.5rem;
  height: 2.5rem;
}

.loading {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
