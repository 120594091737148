@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.bonusName {
  margin-bottom: 1rem;
}

.textArea {
  height: 10rem;
}

.counter {
  @include XSnavyleft;
}

.icon {
  margin-top: -1.5rem;
}

.amountFrequencyContainer {
  display: flex;
  justify-content: space-between;
}

.percentageDropdownFrequency {
  width: 160px;
}

.percentageTwo {
  width: 304px;
  margin-left: 1rem;
  margin-right: 1rem;
}

.amount {
  width: 15.5rem;
}

.descriptionContainer {
  margin-top: 1rem;
}

.toggleContainer {
  margin: 1.5rem 0 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .inputsContainer {
    display: flex;
    flex-direction: row;
  }

  .input {
    margin-right: 1rem;
    width: 11rem;
  }
}

.inputPercentage {
  width: 9rem;

  input {
    width: 3rem;
  }
}

.inputFrequencyPercentage {
  width: 5rem;
}

.amountDropdownFrequency {
  width: 16rem;
}
