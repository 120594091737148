@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  flex: 1;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  background-color: $main-navy-full;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  .icon {
    position: absolute;
    top: 2rem;
    right: 2.25rem;
    height: 200px;
    z-index: 4;
    overflow-y: visible;
  }

  .searchContainer {
    height: 12.25rem;
    min-height: 12.25rem;
    padding: 0 2.25rem;

    .title {
      @include XLwhiteleft;
      margin-bottom: 1rem;
    }

    .companyPicker {
      max-width: 40rem;
    }

    .hello {
      @include Mwhiteleft;
    }
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.container::-webkit-scrollbar {
  display: none;
}
