@import '@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  margin-top: 1.5rem;
}

.cityContainer {
  width: 75%;
}

.fieldContainer {
  margin-bottom: 1rem;
}
