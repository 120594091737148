@import '@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

$upper-height: 9.5rem;
$lower-height: 8rem;
$card-height: calc(#{$lower-height} + #{$upper-height});
$column-gap: 1rem;

.container {
  background-color: $main-white-full;
  flex-direction: column;
  height: $card-height;
  border-radius: 8px;
  cursor: pointer;
}

.container:hover {
  box-shadow: 0 0 0 2px $main-blue-full;
}

.description {
  @include XSgrey_mediumleft;
}

.upperPart {
  background-color: $main-white-full;
  padding: 0.75rem 0.75rem;
  height: $upper-height;
  border-radius: 8px 8px 0 0;

  .firstRow {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 1.75rem;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  .date {
    @include XSgrey_mediumleft;
    position: relative;
    top: 0.5rem;
  }

  .service {
    @include Mblueleft;
    margin-top: 0.5rem;
    height: 3rem;
    // Ellipsis on second line
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .serviceAddress {
    @include XSgrey_mediumleft;
    margin-top: 0.5rem;
    // Ellipsis on second line
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.lowerPart {
  background-color: $main-blue-lighter;
  padding: 1rem 0.75rem;
  height: $lower-height;
  border-radius: 0 0 8px 8px;

  .qualification {
    @include Sgrey_mediumleft;
    height: 2.5rem;
    // Ellipsis on second line
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .name {
    @include Snavyleft;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    height: 2.5rem;
    // Ellipsis on second line
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
