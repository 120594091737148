@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.recruitmentOfficerCheckbox {
  margin-top: 1rem;
}

.decisionMakerCheckbox {
  margin-top: 1rem;
}

.comboBoxContainer {
  margin-top: 1rem;
}
