.rubricsContainer {
  display: grid;
  grid-template-columns: auto auto;
  font-size: 1rem;
}
.allCheckbox {
  padding-bottom: 0.75rem;
}

.checkboxes {
  display: grid;
  grid-template-columns: auto;
  padding-left: 1rem;
}

.checkbox {
  display: flex;
  flex: 1 0 40%;
  align-items: center;
  padding-bottom: 0.75rem;
}

.modalIcon {
  margin-top: -1.5rem;
}
