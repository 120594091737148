@import '@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.autoSuggestContainer {
  margin-top: 16px;
}

.badgesContainer {
  margin-top: 16px;
}

.badge {
  margin-top: 0.5rem;
  margin-right: 0.5rem;
  background-color: $main-navy-full;
}
