@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex-direction: row;
  padding-left: 2rem;
  overflow-y: scroll;
  padding-top: 1.5rem;
  width: 100%;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.routeNumber {
  margin-top: 0.7rem;
  width: 1.5rem;
  @include Mnavyleft;
}

.formContainer {
  flex: 1;
  flex-direction: column;
  display: flex;
  max-width: 61.5rem;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.container::-webkit-scrollbar {
  display: none;
}

.titleContainer {
  padding: 0 0 1.5rem 0;

  p {
    @include XLnavyleft;
  }
}

.content {
  flex: 1;
  flex-direction: column;
  display: flex;
  padding-bottom: 1rem;
}

.separator {
  height: 1px;
  background-color: $contrast-grey-medium;
}
