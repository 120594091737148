@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.unknown {
  background-color: $main-blue-medium;
}

.optional {
  background-color: $system-info;
}

.mandatory {
  background-color: $system-danger;
}

.selectable * {
  cursor: pointer;
  pointer-events: auto;
}
