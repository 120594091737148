@import '@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  background-color: $main-white-full;
  border-radius: 8px;
  border: 1px solid $contrast-grey-medium;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  padding-right: 1rem;
  margin-bottom: 0.5rem;
  height: 5.75rem;
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.container:hover {
  border-color: $main-white-full;
  box-shadow: 0 0 0 2px $main-blue-full;
}

.description {
  @include XSgrey_mediumleft;
}

.title {
  @include Snavyleft;
  margin-bottom: 0.5rem;
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
