@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.title {
  @include Lnavyleft;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
}

.container {
  padding-bottom: 2rem;
}

.separator {
  height: 1px;
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
  background-color: $contrast-grey-medium;
}

.charCountLabel {
  @include XSnavyleft;
}

.textArea {
  height: 10rem;
  margin-bottom: 1rem;
}

.reportingCard {
  border: solid 1px var(--main-white-none);
}

.reportingCardsSeparator {
  height: 1px;
  background-color: $contrast-grey-medium;
}
