@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.timeContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.lineContainer {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}

.timeHoursPerWeekContainer {
  width: 9rem;
  input {
    width: 7rem;
  }
}

.timeOnCallDutyDetailsArea {
  height: 158px;
}
