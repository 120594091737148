@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.itemContainer {
  margin-bottom: 0.5rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.addButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  @include Sblueleft;

  &.disabled {
    color: $contrast-grey-strong;
  }

  &:not(.disabled) {
    cursor: pointer;

    &:hover:not(:active),
    &:focus-visible {
      outline: none;
    }
  }

  svg path {
    fill: currentColor;
  }
}

.plusCircleIcon {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;

  &.disabled {
    color: $contrast-grey-strong;
  }
}
