@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex-direction: column;
}

.title {
  @include Lnavyleft;
}

.subtitle {
  @include XSgrey_mediumleft;
  margin-bottom: 0.5rem;
}

.gridTitleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.selectedNumber {
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;
  @include Snavyright;
}

.validateButton {
  margin-top: 2.5rem;
  width: 8.75rem;
}

.textArea {
  width: 100%;
  min-height: 10rem;
  margin-bottom: 3rem;
}

.ifSelectionNoOrUnkown {
  margin-bottom: 3rem;
}

.ifSelectionYes {
  margin-bottom: 0;
}

.charCountLabel {
  @include XSnavyleft;
}

.errorMessage {
  @include XSerrorleft;
  margin-top: 0.25rem;
}
