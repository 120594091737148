@import '@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.spaceBottom {
  margin-bottom: 1rem;
}

.modalIcon {
  margin-top: -24px !important;
}

.spacing {
  margin-left: 0.5rem;
}

.container {
  padding: 0;
  margin-top: 1rem;
}
