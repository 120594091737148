@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';
@import './GridCard/GridCard.module.scss';

.cardsContainer {
  padding: 1.5em 2.25rem 1rem 2.25rem;
  background-color: $main-beige-full;
  flex-grow: 1;

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;
    align-items: center;
  }

  .title {
    @include Lnavyleft;
  }

  .studies {
    display: grid;
    column-gap: 1.5rem;
    row-gap: 0.75rem;
  }

  .grid {
    grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
  }
}
