@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.languagePickerContainer {
  width: 448px;
  margin-bottom: 2.5rem;
}

.borderBottom {
  border-bottom: 1px solid $contrast-grey-medium;
}
.languageCard {
  height: 11rem;
}

.languageCardLoader {
  @extend .languageCard;
  padding: 1.75rem;
  border: 1px solid $contrast-grey-strong;
  border-radius: 4px;
}
