@import '@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.title {
  margin-bottom: 1rem;
}

.perimeterSelectionRow {
  display: flex;
  flex: 1;

  > div:not(:last-child) {
    margin-right: 1rem;
  }
}

.comboBoxFormField {
  display: flex;
  flex-direction: column;
  flex: 1;
}
