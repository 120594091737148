@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.openOrCloseUploadSection {
  display: flex;
  margin-top: 2.25rem;
  width: 12.5rem;
  &:hover {
    cursor: pointer;
  }
}

.addDocument {
  color: $main-blue-full;
  margin-left: 0.25rem;
}

.uploadSectionIcon {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}

.documentsContainer {
  margin-top: 2.25rem;
}

.cancelUpload {
  color: $main-blue-full;
  margin-top: 1rem;
  &:hover {
    cursor: pointer;
  }
}
