@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.title {
  @include Lnavyleft;
  margin-top: 0.625rem;
  margin-bottom: 1.5rem;
}

.medicalSpecializationsPicker {
  margin-bottom: 24px;
}

.separator {
  height: 1px;
  background-color: $contrast-grey-light;
}
