@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.title {
  @include Lnavyleft;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.container {
  padding-bottom: 2rem;
}

.managerPicker {
  width: 448px;
  margin-bottom: 2rem;
}

.remoteDaysContainer {
  margin-top: 1.5rem;
}

.tripleChoiceContainer {
  margin-top: 1.5rem;
}

.addButton {
  margin-bottom: 1rem;
}

.addButtonIcon {
  width: 1rem;
  height: 1rem;
}

.checkBoxContainer {
  display: flex;
  flex-direction: row;
  gap: 10rem;
  margin-top: 1rem;
}

.checkBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
}

.subcheckBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
  margin-left: 2rem;
}

.containerTextCheckBox {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  margin-left: 2.5rem;
  width: 25rem;
}

.valueEur {
  margin-top: -1rem;
  width: 9rem;
  input {
    width: 7rem;
  }
}

.frequency {
  margin-top: -1rem;
  width: 10rem;
  margin-left: 1rem;
}

.containerHealth {
  width: 9rem;
  input {
    width: 7rem;
  }
}

.frequencyDrop {
  background-color: white;
}

.insideText {
  max-width: 7.5rem;
}

.healthInsurance {
  margin-top: -1rem;
  margin-bottom: 1rem;
  margin-left: 2.5rem;
  width: 21rem;
}

.pageSubPart {
  margin-bottom: 2.5rem;
}

.inputsContainer {
  display: flex;
  flex-direction: row;
}

.movingDaysContainer {
  display: flex;
}

.unitDropDown {
  width: 10rem;
  background-color: $main-white-full;
  margin-left: 1rem;
  max-height: 3.5rem;
  margin-bottom: 1rem;
}

.remoteInput,
.movingDaysInput {
  width: 9rem;

  input {
    width: 7rem;
  }
}

.byDiv {
  margin-top: 1.2rem;
  margin-left: 1rem;
}

.separator {
  height: 1px;
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
  background-color: $contrast-grey-medium;
}

.bonusesAndVariablesCard {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  background-color: $main-white-full;
  border-radius: 0.5rem;

  &:nth-child(n) {
    margin-top: 0.5rem;
  }
}

.textContainerClassName {
  width: 800px;
}

.selectedVariablesOrBonuses {
  font-size: 1rem;
  @include Snavyright;
}

.mobility {
  margin-bottom: 1rem;
  width: 35.75rem;
}

.textArea {
  height: 10rem;
  margin-bottom: 2rem;
}

.counter {
  @include XSnavyleft;
}
