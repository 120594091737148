@import '@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  padding-left: 2.25rem;
  padding-right: 2.25rem;
  overflow-y: scroll;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.container::-webkit-scrollbar {
  display: none;
}

.goBack {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin-bottom: 1.75rem;
  margin-left: -1rem;
  width: fit-content;
  @include Sblueleft;

  &:hover {
    @include Snavyleft;
    circle {
      fill: $main-navy-full;
    }
  }
}

.goBackArrowButton {
  color: $main-white-full;
  margin-right: 0.5rem;
  display: flex;
  justify-content: center;

  svg {
    width: 3rem;
    height: 3rem;
  }
}

.titleBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;
}

.companyNameContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 4.25rem;
}

.companyName {
  @include XLnavyleft;
}
