@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.combobox {
  margin-bottom: 1rem;
}

.selectAllCheckbox {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.missionCount {
  @include XSgrey_mediumleft;
}

.checkboxBorderTop {
  border-top: 1px solid $contrast-grey-medium;
}

.checkbox {
  padding-left: 1rem;
  margin-right: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $contrast-grey-medium;
}

.checkboxChildren {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 4rem;
  margin-left: 0.5rem;
}

.missionNumber {
  @include Sblueleft;
}

.spanText {
  @include XSblueleft;
}

.missionInfo {
  @include XSgrey_mediumleft;
}

.triggerModification {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.missionTriggerText {
  @include XSnavyleft;
  margin-right: 1rem;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 80%;
  white-space: nowrap;
}
