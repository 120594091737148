@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.threePoints {
  color: $main-white-full;
}

.trigger {
  width: 2.5rem;
  height: 2.5rem;
}
