@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.title {
  @include Lnavyleft;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
}

.textArea {
  height: 10rem;
  margin-bottom: 2rem;
}

.counter {
  @include XSnavyleft;
}
