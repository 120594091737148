@import '@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.gearButton {
  color: $main-white-medium !important;
  padding: 0.5rem !important;
  border-width: 0rem !important;
  height: 3rem !important;
  width: 3rem !important;
  min-width: 0rem !important;
}

.openGearButton {
  outline: none !important;
  background-color: $contrast-grey-lighter !important;

  svg {
    color: $main-white-full !important;
  }
}

.circleRandstad {
  margin-right: 0.5rem;
}

.separator {
  height: 1px;
  background-color: $contrast-grey-medium;
}

.button {
  width: 110%;
  margin-right: -5%;
  margin-left: -5%;
  padding-right: 1rem;
  justify-content: start;
}
