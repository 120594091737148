@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.toggleContainer {
  margin: 1.5rem 0 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.modalityContainer {
  margin-top: 1rem;
}

.contactContainer {
  margin-top: 1.5rem;
}

.leftIconDrag {
  display: flex;
}

.recruitmentStepLeftIcon {
  line-height: 3.5rem;
}

.recruitmentStepCard {
  display: flex;
  flex-direction: row;
  height: 74px;

  :hover {
    background-color: transparent !important;
  }

  :active {
    background-color: transparent !important;
  }
}

.addButtonStep {
  padding-left: 0.5rem;
  margin-bottom: 1.5rem;
}
