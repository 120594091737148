@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.tripleChoiceContainer {
  margin: 1.5rem 0 1rem 0;
}

.separator {
  height: 1px;
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
  background-color: $contrast-grey-medium;
}

.handicapsTitle {
  margin-bottom: 2rem;
}

.subtitle {
  margin-bottom: 2rem;
}

.dragItem {
  display: flex;
  align-items: center;
  background-color: white;
  height: 4.5rem;
  width: 100%;
  margin-bottom: 0.5rem;
  padding: 1rem;
  border-radius: 8px;
  column-gap: 1rem;
}

.dragIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
}

.qualificationStyle {
  width: 99.5%;
}

.plusIcon {
  height: 1rem;
}

.container {
  margin-top: 2rem;
}

.environmentsTitle,
.managementTitle {
  margin-bottom: 2rem;
}

.risksTitle {
  margin-bottom: 2rem;
}

.jobCard {
  display: flex;
  flex-direction: row;
  height: 74px;

  :hover {
    background-color: transparent !important;
  }

  :active {
    background-color: transparent !important;
    border: 0 !important;
  }
}

.containerDrag {
  :hover {
    background-color: $contrast-grey-lighter;
  }

  :active {
    background-color: $contrast-grey-light;
    border-radius: 8px;
    border: 2px solid $main-blue-full;
  }
}

.leftIconDrag {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  left: -1rem;
}

.qualificationCountBadge {
  display: flex;
  background-color: $main-yellow-full !important;
  justify-content: center;
  align-items: center;
  color: black;
}

.badges {
  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    display: inline-flex;
    background-color: $main-yellow-full !important;
    border-radius: 2.375rem;
    margin: 0 !important;
  }

  :active {
    display: inline-flex;
    background-color: $main-yellow-full !important;
    border-radius: 2.375rem;
    margin: 0 !important;
  }
}

.title {
  margin: 0;
  line-height: 1.5;
}

.textArea {
  height: 10rem;
  margin-bottom: 2rem;
}

.managementSkillsTextArea {
  height: 10rem;
}

.counter {
  @include XSnavyleft;
}

.numberOfPeopleToManageContainer {
  width: 9rem;
  margin-bottom: 1.5rem;
  input {
    width: 7rem;
  }
  p {
    width: 13rem;
  }
}

.numberOfPeopleToManageInput {
  input {
    width: 7rem;
  }
}
