@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  padding-bottom: 2rem;
}

.informationContainer {
  margin-top: 1.5rem;
}

.siretSectorContainer {
  display: flex;
  justify-content: space-between;
}

.tripleChoiceContainer {
  margin: 1.5rem 0 1rem 0;
}

.beforeTitle {
  width: 28rem;
}

.separator {
  height: 1px;
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
  background-color: $contrast-grey-medium;
}

.competitorCard {
  background-color: white;
  border: solid 1px $contrast-grey-medium;
  border-radius: 4px;
  margin-bottom: 0.5rem;
}

.flashyGreen {
  background-color: #3ba817;
}

.icedBlue {
  background-color: #21c0d9;
}

.yellowFull {
  background-color: $main_yellow_full;
}
