@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.salaryContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.salaryFirstRowContainer {
  display: flex;
  flex-direction: row;
  gap: 3.5rem;
}

.lineContainer {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.countInput {
  width: 9rem;

  input {
    width: 7rem;
  }
}
