@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.title {
  @include Lnavyleft;
  margin-top: 0.625rem;
  margin-bottom: 1.5rem;
}

.diplomasContainer {
  margin-top: 2.5rem;
}

.textArea {
  height: 10rem;
  margin-bottom: 2rem;
}

.charCountLabel {
  @include XSnavyleft;
}
