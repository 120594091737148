@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.button {
  height: 3rem;
  @include Sblueleft;

  cursor: pointer;

  &:hover:not(:active),
  &:focus-visible {
    outline: none;
  }
}

.separator {
  display: flex;
  align-items: center;
  margin: 0 0.5rem;
  @include Snavyleft;
}
