@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.timeContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.timeDaysPerYearContainer {
  width: 9rem;

  input {
    width: 7rem;
  }
}

.timeDaysPerYearInput {
  max-width: 110px;
}
